import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TownProject} from '../../town-project';
import {TownProjectService} from '../../services/townproject/town-project.service';

@Component({
    selector: 'app-town-project-export-dialog',
    templateUrl: './town-project-export-dialog.component.html',
    styleUrls: ['./town-project-export-dialog.component.scss']
})
export class TownProjectExportDialogComponent implements OnInit {

    project: TownProject;

    form: FormGroup;
    statusCounts: {
        name: string,
        count: number,
        colorcode: string,
        id: number
    }[];

    constructor(private townProjectService: TownProjectService,
                private dialog: MatDialogRef<TownProjectExportDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    project?: TownProject,
                    type: string
                }) {
        this.project = data.project;
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            projectId: new FormControl(this.data.project.id),
            workarea_per_page: new FormControl(false)
        });
        this.townProjectService.getProjectsWorkareaCount(this.project.id).subscribe(counts => {
            this.statusCounts = counts;
            this.form.addControl('status', new FormGroup(
                this.statusCounts.reduce((controls, status) => ({...controls, [status.id]: new FormControl()}), {})
            ));
        });
    }

    donwload() {
        this.dialog.close(this.form.value);
    }
}
