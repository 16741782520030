<div *ngIf="type === 'drillings'" class="workarea-list">
    <h1>Details boorkernen</h1>
    <div *ngFor="let workarea of filteredWorkareas" class="workarea">
        <div class="title">
            <h2>Boorkern {{workarea.number}} - {{workarea.street}} {{workarea.housenumber}} {{workarea.place}}</h2>
            <h4><a href="http://www.google.com/maps/place/{{workarea.lat}},{{workarea.lng}}" target="_blank">{{workarea.lat}}, {{workarea.lng}}</a></h4>
        </div>
        <div class="image">
            <img *ngFor="let image of workarea.images" [src]="image.image_url + '?thumb=true'" class="float-left" height="200" width="200">
        </div>
        <div class="details">
            <table>
                <tr>
                    <td width="140">Boorlocatie:</td>
                    <td>{{workarea.extra?.drillLocation}}</td>
                </tr>
                <tr>
                    <td>Rijstrook:</td>
                    <td>{{workarea.extra?.lane}}</td>
                </tr>
                <tr>
                    <td>Lengte boorkern:</td>
                    <td class="text-right">{{workarea.extra?.drill_length}} cm</td>
                </tr>
            </table>
            <div class="font-italic">{{workarea.comments}}</div>
        </div>
    </div>
</div>
<div *ngIf="type !== 'drillings'" class="workarea-list general">
    <h1 *ngIf="!filter.workarea">Details werkvakken</h1>
    <div *ngFor="let workarea of filteredWorkareas" class="workarea" [class.break-after]="workareaPerPage">
        <div class="title">
            <h2>Werkvak {{workarea.number}} - {{workarea.street}} {{workarea.housenumber}}, {{workarea.zipcode}} {{workarea.place}}</h2>
            <h4>
                <a href="http://www.google.com/maps/place/{{workarea.lat}},{{workarea.lng}}" target="_blank">{{workarea.lat}}, {{workarea.lng}}</a>
                <ng-container *ngIf="workarea.road_number">
                    Wegnummer: {{workarea.road_number}}
                </ng-container>
            </h4>
        </div>
        <div class="image">
            <img *ngFor="let image of workarea.images" [src]="image.image_url + '?thumb=true'" class="float-left" height="200" width="200">
        </div>
        <div class="details w-100">
            <div class="font-italic" [innerHTML]="workarea.comments | nl2br"></div>
            <div class="row pt-2">
                <div class="col-6">
                    <table>
                        <tr>
                            <td>Status:</td>
                            <td>{{workarea?.status_name}}</td>
                        </tr>
                        <tr>
                            <td width="140">Locatie:</td>
                            <td>{{workarea.extra?.location_damage}}</td>
                        </tr>
                        <tr>
                            <td>Type:</td>
                            <td>{{workarea.extra?.damage}}</td>
                        </tr>
                        <tr>
                            <td>Schade Locatie:</td>
                            <td>{{workarea.extra?.location_damage}}</td>
                        </tr>
                        <tr>
                            <td>Klinkervak:</td>
                            <td>{{workarea.extra?.klinkerfloor ? 'Ja' : 'Nee'}}</td>
                        </tr>
                        <tr>
                            <td>Bewapeningsnet:</td>
                            <td>{{workarea.extra?.reinforcementgrid ? 'Ja' : 'Nee'}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                        </tr>


                        <tr>
                            <td>Toplaag:</td>
                            <td>{{workarea.extra?.toplayer}}</td>
                        </tr>
                        <tr>
                            <td>Tussenlaag:</td>
                            <td>{{workarea.extra?.interlayer}}</td>
                        </tr>
                        <tr>
                            <td>Onderlaag:</td>
                            <td>{{workarea.extra?.bottomlayer}}</td>
                        </tr>

                    </table>
                </div>
                <div class="col-6">
                    <table>
                        <tr>
                            <td>Wegmarkering:</td>
                            <td>{{workarea.extra?.waymark}}</td>
                        </tr>
                        <tr>
                            <td>Wegafzetting:</td>
                            <td>{{workarea.extra?.roadblock}}</td>
                        </tr>
                        <tr>
                            <td>Uitvoering:</td>
                            <td>{{workarea.extra?.execution}}</td>
                        </tr>
                        <tr>
                            <td>Prioriteit:</td>
                            <td>{{workarea.extra?.genpriority}}</td>
                        </tr>
                        <tr>
                            <td>Afzetting:</td>
                            <td>{{workarea.extra?.roadblock}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="2">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="p-0">

                                <table *ngIf="workarea.areas?.length">
                                    <tr>
                                        <th>
                                            Lengte
                                        </th>
                                        <th *ngIf="workarea.extra.damage != 'Scheuren'">
                                            Breedte
                                        </th>
                                        <th width="250">
                                            Oppervlakte
                                        </th>
                                    </tr>
                                    <tr *ngFor="let area of workarea.areas; let i = index">
                                        <td>
                                            {{area.length}} m
                                        </td>
                                        <td *ngIf="workarea.extra.damage != 'Scheuren'">
                                            {{area.width}} m
                                        </td>
                                        <td class="col-6 col-md-6 d-flex">
                                            <div>
                                                {{(area.width * area.length) | number:'0.0-2' }} m<sup *ngIf="workarea.extra.damage != 'Scheuren'">2</sup>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Totaal:</b>
                                        </td>
                                        <td *ngIf="workarea.extra.damage !== 'Scheuren'"></td>

                                        <td>
                                            {{areasTotal(workarea.areas) | number:'0.0-2'}} m<sup>2</sup>
                                        </td>
                                    </tr>

                                </table>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>


        </div>
    </div>
</div>
