import {ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {saveAs} from 'file-saver';
import {TownService} from '../services/town/town.service';
import {TownProjectService} from '../services/townproject/town-project.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Storage} from '../storage.class';
import {TownProject} from '../town-project';
import {Town} from '../town.class';
import {PdfreportService} from '../pdfreport.service';
import {FileItem} from '../document-upload/file-item';
import {FileService} from '../services/file.service';
import {TownProjectDrilling, TownProjectDrillingOptions} from '../town-project-drilling';
import {debounceTime, map} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ExportService} from '../services/export/export.service';
import {TownProjectPhase} from '../town-project-phase';
import {DomSanitizer} from '@angular/platform-browser';
import {Settings} from 'app/settings.class';
import {StorageName} from '../document-upload/storage-name.enum';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {TownProjectEditDialogComponent} from '../town-project-edit-dialog/town-project-edit-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Routenames} from '../route-names.enum';
import {CodaltComponent} from '../codalt.component';
import {TownProjectPhaseActivity} from '../town-project-phase-activity';
import {TownProjectPhaseRepliesComponent} from './town-project-phase-replies/town-project-phase-replies.component';
import {formatDate} from '@angular/common';
import {BreakpointObserver} from '@angular/cdk/layout';
import {TownProjectDescriptionDialogComponent} from './town-project-description-dialog/town-project-description-dialog.component';
import {Utils} from '../utils.class';
import {TownProjectExportDialogComponent} from './town-project-export-dialog/town-project-export-dialog.component';

@Component({
    selector: 'app-town-project-detail',
    templateUrl: './town-project-detail.component.html',
    styleUrls: ['./town-project-detail.component.scss']
})
export class TownProjectDetailComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild('excelExportModal', {static: true}) private excelExportModalTemplate: TemplateRef<any>;

    @ViewChild('townProjectPhaseReplies')
    townProjectPhaseReplies: TownProjectPhaseRepliesComponent;

    generatingReport = false;
    generatingExcel = false;
    project: TownProject | TownProjectDrilling;
    selectedPhase?: TownProjectPhase;
    town: Town;
    townId: number;
    projectId: number;
    form: FormGroup;
    fc: {
        driller,
        drillingType,
        foundationType,
        description,
        worknumber,
        street,
        customer,
        execution_date
    };
    dropdownOptions;
    counts: {
        name: string,
        count: number,
        colorcode: string,
        id: number
    }[];
    Settings = Settings;
    hideSidebar = false;
    isMobile = false;

    zipExporting = false;
    statusIdsToExport = [];

    selectedActivity: TownProjectPhaseActivity;

    highlightActivityReplyId: number;

    constructor(private townService: TownService,
                private townProjectService: TownProjectService,
                private pdfreportService: PdfreportService,
                private fileService: FileService,
                private confirmDialog: ConfirmDialogService,
                private dialog: MatDialog,
                private exportService: ExportService,
                private dom: DomSanitizer,
                private cdref: ChangeDetectorRef,
                public router: Router,
                private matDialog: MatDialog,
                private breakpointObserver: BreakpointObserver,
                private route: ActivatedRoute) {
        super();
    }

    ngOnInit(): void {
        if (this.breakpointObserver.isMatched('(max-width: 767px)')) {
            this.hideSidebar = true;
            this.isMobile = true;
        }
        this.subscriptions.add(this.route.parent.params.subscribe(params => {
            this.townId = +params['townId'];
            this.townService.getSingle(this.townId).then(town => {
                this.town = town;
                Storage.setTown(town);
            });
        }));

        this.subscriptions.add(this.route.params.subscribe(params => {
            this.projectId = +params['projectId'];
            this.townProjectService.getProjectsWorkareaCount(this.projectId).subscribe(counts => {
                this.counts = counts;
            });

            this.townProjectService.getProject(this.projectId)
                .pipe(map(p => p.data))
                .subscribe(project => {
                    project.extra = project.extra ? project.extra : {};
                    if (project.type === 'drillings') {
                        this.dropdownOptions = TownProjectDrillingOptions;
                        this.project = project as TownProjectDrilling;
                        this.cdref.detectChanges();
                        this.fc = {
                            driller: new FormControl(this.project.extra.driller ? this.project.extra.driller : Storage.getUser().name, Validators.required),
                            drillingType: new FormControl(this.project.extra.drillingType, Validators.required),
                            foundationType: new FormControl(this.project.extra.foundationType, Validators.required),
                            description: new FormControl(this.project.extra.description),
                            execution_date: new FormControl(this.project.extra.execution_date),
                            worknumber: new FormControl(this.project.extra.worknumber),
                            street: new FormControl(this.project.extra.street),
                            customer: new FormControl(this.project.extra.customer ? this.project.extra.customer : this.town?.name)
                        };
                        this.form = new FormGroup(this.fc);
                        if (Utils.userIsGroup('EMPLOYEE_READ')) {
                            this.form.disable();
                        }
                        this.subscriptions.add(this.form.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                            if (!this.project.extra) {
                                this.project.extra = {};
                            }
                            Object.assign(this.project.extra, this.form.value);
                            this.townProjectService.saveProject(this.project).subscribe();
                        }));
                    } else {
                        this.project = project;
                        this.cdref.detectChanges();
                        if (params['phase']) {
                            this.selectedPhase = this.project.town_project_phases.find(p => p.id === +params['phase']);
                            if (params['phaseActivity']) {
                                this.selectedActivity = this.selectedPhase.town_project_phase_activities.find(a => a.id === +params['phaseActivity']);
                            }
                            if (params['reply']) {
                                this.highlightActivityReplyId = +params['reply'];
                            }
                        }

                    }
                }, error => {
                    this.confirmDialog.confirm('Fout bij het laden', error.message, 'Terug', null).then(() => {
                        this.router.navigate(['/', Routenames.towns, this.town.id, 'projects']);
                    });
                });
        }));
    }

    zipExport() {
        this.zipExporting = true;
        this.townProjectService.zip(this.project.id).subscribe(zip => {
            const date = formatDate(new Date(), 'dd-MMM-yyyy', 'nl').replace('.', '');
            saveAs(zip, `Documenten ${this.project.name} ${date}.zip`);
            this.zipExporting = false;
        });
    }

    projectDescription() {
        this.dialog.open(TownProjectDescriptionDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: {
                project: this.project
            }
        });
    }

    projectModal(project?: TownProject) {
        this.dialog.open(TownProjectEditDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: {
                town: this.town,
                project
            }
        }).afterClosed().subscribe((project: TownProject) => {
            if (project) {
                Object.assign(this.project, project);
            } else {
                this.router.navigate(['/', Routenames.towns, this.town.id, 'projects']);
            }
        });
    }

    sidebarToggle() {
        this.hideSidebar = !this.hideSidebar;
    }

    save() {
        this.townProjectService.saveProject(this.project).subscribe();
    }

    public downloadReport() {
        const dialogRef = this.dialog.open(TownProjectExportDialogComponent, {
            maxHeight: '98vh',
            panelClass: 'confirm-dialog',
            data: {
                project: this.project,
                type: 'Pdf'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.generatingReport = true;
            this.pdfreportService.getReportPdf(result).subscribe(document => {
                this.generatingReport = false;
                saveAs(document, `${this.project.type === 'drillings' ? 'Boorrapport' : 'Rapport DvdS'} ${this.town.name} - ${this.project.name}.pdf`);
            });
        });
    }

    deleteReport(filename) {
        this.confirmDialog.confirm(
            'Verwijderen',
            'Weet je zeker dat je dit document wilt verwijderen?',
            'Verwijderen',
            'Behouden').then(() => {
            this.fileService.delete(`${this.project.id}/${filename}`, StorageName.drillings).subscribe(() => {
                this.project.extra.reports.splice(this.project.extra.reports.indexOf(filename), 1);
                this.townProjectService.saveProject(this.project).subscribe();
            });
        }, () => {

        });
    }

    fileUploaded(file: FileItem) {
        if (!this.project.extra.reports) {
            this.project.extra.reports = [];
        }
        this.project.extra.reports.push(file.filename);
        this.townProjectService.saveProject(this.project).subscribe();
    }

    drop(event: CdkDragDrop<string[]>, items: any[]) {
        moveItemInArray(items, event.previousIndex, event.currentIndex);
    }

    exportExcel() {
        this.generatingExcel = true;
        this.exportService.downloadExcelWorkareas(this.project.id, this.statusIdsToExport).subscribe((document) => {
            saveAs(document, `Excel ${this.town.name} - ${this.project.name}.xlsx`);
            this.generatingExcel = false;
        }, error => {
            this.generatingExcel = false;
            const err = error['error']['error']['errormessage'];
            this.confirmDialog.confirm('Oeps...', `Er is iets fout gegaan op de server ${err}`, 'Oke', null).then(() => {
            });
        });
    }

    exportExcelDialog() {

        this.subscriptions.add(this.matDialog.open(this.excelExportModalTemplate, {
            panelClass: 'add-townproject-dialog',
            width: '300px',
            maxHeight: '100vh'
        }).afterClosed().subscribe(() => {
            this.exportExcel();
        }));
    }


    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
