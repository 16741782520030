import {Injectable} from '@angular/core';
import {ApiService} from './services/api/api.service';

@Injectable({
    providedIn: 'root'
})
export class PdfreportService {

    constructor(private apiService: ApiService) {
    }

    public getReport(projectId?: number) {
        return this.apiService.getCall$(`pdf/report`, {projectId});
    }

    public getReportPdf(data: { projectId?: number, status: any; workarea_per_page: boolean; }) {
        return this.apiService.getBlobCall(`pdf/reportpdf`, {
            projectId: data.projectId,
            status:  Object.keys(data.status).filter(key => data.status[key]),
            workarea_per_page: data.workarea_per_page
        });
    }

    public getWorkareaPdf(workareaId?: number) {
        return this.apiService.getBlobCall(`pdf/workareapdf`, {workareaId});
    }
}
